.App-DaysUp-container .form-horizontal {
    min-height: 100px;
}

.App-DaysUp-total {
    text-align: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
}
