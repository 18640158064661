.App-TotalWh-container .form-horizontal {
    min-height: 100px;
}

.App-TotalWh-container .accordion-button {
    background: #f1f1f1 !important;
    color: #212529 !important;
    font-weight: bold;
}

.App-TotalWh-container .accordion-button.collapsed {
    background: #fff !important;
    /*color: white !important;*/
    font-weight: bold;
}

.App-TotalWh-container p.App-TotalWh-container-total-wh {
    font-weight: bold;
    font-size: 26px;
    margin: -1px auto 10px auto;
    border-radius: 3px;
    width: 50%;
    font-family: monospace;
    box-shadow: inset 0 3px 9px -5px rgba(0, 0, 0, 0.7);
}

.App-TotalWh-base-result {
    text-align: justify;
    margin-bottom: 20px;
}

.App-TotalWh-base-result table {
    width: 100%;
    margin-top: 10px;
}

.App-TotalWh-base-result td {
    text-align: left;
}

.App-TotalWh-base-result td:last-of-type {
    min-width: 50px;
}

.App-TotalWh-base-result tr {
    border-top: 1px solid #e9e7e7;
    border-bottom: 1px solid #e9e7e7;
}

.App-TotalWh-container .accordion {
    margin-bottom: 10px;
}

@media (max-width: 800px) {
    .App-TotalWh-base-result td {
        font-size: 12px;
    }
}
