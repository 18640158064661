.App-SunHours-container .form-horizontal {
    min-height: 100px;
}

.App-SunHours {
    display: flex;
    height: 38px;
    justify-content: space-between;
}

.App-SunHours-zipCode {
    width: 40%;
    display: flex;
}

.App-SunHours-zipCode > .form-control {
    width: 62%;
}

.App-SunHours-average {
    display: flex;
    align-items: center;
}

.App-SunHours-average .form-control {
    width: 76px;
}

@media (max-width: 800px) {
    .App-SunHours-container .form-horizontal {
        min-height: 130px;
    }

    .App-SunHours {
        display: flex;
        height: 62px;
    }

    .App-SunHours-zipCode {
        flex-direction: column;
    }

    .App-SunHours-zipCode > .form-control {
        width: 100%;
    }

    .App-SunHours-average {
        flex-direction: column-reverse;
    }
}

